import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"primary\"}");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/toast-notify.tsx");
import(/* webpackMode: "eager" */ "/app/src/lab-test/context/lab-test-context.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/context/alert-state.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/context/category-state.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/context/ref-partner-api.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/context/session-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/context/user-cart.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/scss/globals.scss")